import { augmentedOccasionsApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { AugmentedOccasionsDto } from 'types/dto/augmentedOccasionsDto'

export interface Props {
  userId: string
  brief: string
  brand: string
  name: string
  country: string
  saved: boolean
}

export const createPrompt = ({
  userId,
  brief,
  brand,
  name,
  country,
  saved,
}: Props): CancelableRequestProducer<AugmentedOccasionsDto> =>
  augmentedOccasionsApi.post('/prompt', { userId, brief, name, brand, country, saved })
